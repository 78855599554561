import React from "react"
import Layout from "../components/Layout"
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"

export default () => {
  return (
    <Layout>
       <Helmet>
      <title>VM Construction - 404 - Page not found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex flex-col justify-center items-center bg-gray-darkest h-screen">
        <div className="text-7xl text-yellow font-bold font-rubik">404</div>
        <div className="text-xl text-gray-light">Whoops! Page not found.</div>
        <Link className="text-gray-light underline uppercase font-rubik font-bold" to="/">Go home</Link>
      </div>
    </Layout>
  )
}
